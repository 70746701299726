.hire-me-btn {
    background-color: #3ed1c9;
}

#three-js-container {
    position: relative;
    height: 600px;
    width: 750px;
}

@media(max-width: 1800) {

    .left,
    .right {
        position: relative;
        left: -50px;
    }
}

@media(max-width: 1550) {

    .left,
    .right {
        position: relative;
        left: -100px;
    }
}

@media(max-width: 1350) {

    .left,
    .right {
        position: relative;
        left: -150px;
    }
}

@media(max-width: 1110) {

    .left,
    .right {
        position: relative;
        left: -200px;
    }
}

@media(max-width: 1000) {

    .left,
    .right {
        position: relative;
        left: -300px;
    }
}

@media(max-width: 1330) {

    .nav-links {
        position: absolute;
        visibility: hidden;
    }
}

.navbar-links {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    display: flex;
    position: absolute;
}

.navbar-links.visible {
    opacity: 1;
    position: static;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}


.logo {
    transition: margin 1s ease-in-out;
    font-family: 'Nanum Brush Script', cursive;
}

.logo.centered {
    margin-left: auto;
    margin-right: auto;
}

.navbar-link.white {
    color: white;
}

.navbar-link:hover {
    color: white;
}

.hamburger-icon {
    display: none;
    /* Initially hidden */
}

.close-icon {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-icon::before,
.close-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: white;
}

.close-icon::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}


@media (max-width: 825px) {
    .navbar-links {
        display: none;
    }

    .hamburger-icon {
        display: block;
        /* Show hamburger icon */
    }

    /* Style for hamburger lines */
    .line {
        width: 25px;
        height: 2px;
        background-color: white;
        margin: 5px;
    }

    .custom-visibility-class .close-icon {
        display: block;
    }

    .left,
    .right {
        position: relative;
        left: -360px;
    }
}

@media(max-height: 730px) {
    .nav-links a {
        padding: 10px 0;
    }
}

@media(max-width: 771px) {
    #home {
        height: auto;
    }

    .left {
        left: -280px;
        padding-bottom: 0;
    }

    .right {
        margin-left: 0 !important;
        left: 0;
        padding-top: 0;
    }

    .right div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hire-me-btn {
        margin-right: 0;
    }
}

@media(max-width: 680px) {
    .left {
        left: -270px;
    }
}

@media(max-width: 650px) {
    .navbar-container {
        padding: 0 10px;
    }
}

@media(max-width: 642px) {
    .nav-links a {
        padding: 4px 0;
    }

    .left {
        top: -90px;
    }

    .right {
        top: -110px;
        padding-bottom: 0;
    }
}

@media(max-width: 428px) {
    .name-text {
        font-size: 3rem;
    }

    .skill-scale {
        width: 20rem;
    }
}


.custom-visibility-class {
    visibility: visible;
    right: 0;
    position: fixed;
    z-index: 100;
    width: 200px;
}

/* portfolio */
.carousel {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.card {
    width: 18rem !important;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 15px 25px;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    text-align: center;
    /* Match background color */
}

.card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    /* Add border-radius to images */
}

.card h3 {
    margin-top: 10px;
    font-size: 18px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.tag {
    color: black;
    margin: 0 5px;
    padding: 2px 10px;
    border: 1px solid #949494;
    border-radius: 20%;
    font-size: 10px;
    background-color: white;
}

.card p {
    margin-top: 10px;
    font-size: 14px;
}

.slick-list {
    margin: 0;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
    margin: 0;
}

.social-icon {
    color: #3ed1c9;
}

.slick-track {
    display: flex;
    justify-content: center;
}

/* Center the navigation dots */
.slick-dots {
    bottom: -30px;
    /* Adjust the value as needed */
    display: flex;
    justify-content: center;
    width: 100%;
}

@media(max-width: 855px) {
    .all-skills {
        display: block;
    }
}

.dots-container .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    /* Lighter color for inactive dots */
    cursor: pointer;
    transition: all 0.3s ease;
    /* Add transition for smooth scaling */
}

.dots-container .dot.slick-active {
    background-color: #333;
    /* Darker color for the active dot */
    width: 14px;
    /* Increase the size of the active dot */
    height: 14px;
}